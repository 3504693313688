const Navbar = () => {
   

    return (  
    
        <nav className="navbar">
            <a href="https://emzuri.com/ar/">
             <img src="/emzuriLogo.webp" alt="Emzuri Logo"/>
            </a>
          
         
        
         </nav>
 

    );
}
 
export default Navbar;