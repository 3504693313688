

//components
import WarrantyForm from "../components/WarrantyForm";

 const Home = () => {
    return ( 
        <div >
            <WarrantyForm/>
        </div>
     );
 }
  
 export default Home;