import cn from 'classnames';
import { findInputError, isFormInvalid } from '../utils';
import { useFormContext } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../index.css';

/**
 * Input component for rendering input fields with validation and error handling.
 * @param {Object} props - Component props.
 * @param {string} props.name - Name of the input field.
 * @param {string} props.label - Label for the input field.
 * @param {string} props.type - Type of the input field (e.g., text, email).
 * @param {string} props.id - ID of the input field.
 * @param {string} props.placeholder - Placeholder text for the input field.
 * @param {Object} props.validation - Validation rules for the input field.
 * @param {boolean} props.multiline - Whether the input field should be a textarea.
 * @param {string} props.className - Additional CSS classes for styling.
 * @param {Function} props.onChange - Function to handle onChange event.
 * @returns {JSX.Element} Input component.
 */
export const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  multiline,
  isPhoneInput,
  className,
  onChange,
}) => {
  const { register, formState: { errors } } = useFormContext();

  // Find errors for the current input field
  const inputErrors = findInputError(errors, name);
  // Check if there are any errors
  const isInvalid = isFormInvalid(inputErrors);

  // Base CSS class for input
  const input_tailwind =
    'p-5 font-medium rounded-md w-full border border-slate-300 placeholder:opacity-60';




  return (
    <div className={cn('flex flex-col w-full gap-2', className)}>
      <div className="flex justify-between err-msg">
        <label htmlFor={id} className="font-semibold capitalize">
          {label}
        </label>
        <AnimatePresence mode="wait" initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
        </AnimatePresence>
      </div>
      {isPhoneInput ? (
        <PhoneInput
          id={id}
          placeholder={placeholder}
          {...register(`${name}`, validation)}
          onChange={onChange}
          countryCallingCodeEditable={false}
          defaultCountry="SA"
          inputProps={{ id, name }} // Pass id and name for proper validation
          aria-label={label} // Provide label as aria-label for accessibility
          
          
        />
      ) : (
        <>
          {multiline ? (
            <textarea
              id={id}
              type={type}
              className={cn(input_tailwind, 'min-h-[10rem] max-h-[20rem] resize-y')}
              placeholder={placeholder}
              {...register(`${name}`, validation)}
              onChange={onChange} // Apply onChange to input
            ></textarea>
          ) : (
            <input
              id={id}
              type={type}
              className={cn(input_tailwind)}
              placeholder={placeholder}
              {...register(`${name}`, validation)}
              onChange={onChange} // Apply onChange to input
            />
          )}
        </>
      )}
    </div>
  );
};

/**
 * Error component for displaying validation error messages.
 * @param {Object} props - Component props.
 * @param {string} props.message - Error message to display.
 * @returns {JSX.Element} Error component.
 */
const InputError = ({ message }) => {
  return (
    <motion.p
      className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md error"
      {...framer_error}
    >
      {message}
    </motion.p>
  );
};

// Framer Motion animation settings for error message
const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
