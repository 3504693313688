import { LazyLoadImage } from "react-lazy-load-image-component";
const Footer = () => {


    return ( 
        
        <footer className="store-footer">
<svg className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 store-footer__curve z-[0]" preserveaspectratio="none" viewbox="0 0 1440 54">
<path fill="#F3F4F6" d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L2000 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"></path>
</svg>
<div>
<div aria-label="footer" className="store-footer__inner">
<div className="container grid grid-col-1 lg:grid-cols-3 gap-4 lg:gap-6">
<div className="text-center order-1 lg:order-[unset]">
<h3>روابط مهمة</h3>
<div className="footer-list store-links-items">
<a href="https://emzuri.com/ar/سياسة-الضمان/page-1292967680" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">سياسة الضمان</a>
<a href="https://emzuri.com/ar/سياسة-الش-روط-والأحك-ام/page-795422777" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">سياسة الشـروط والأحكــام</a>
<a href="https://emzuri.com/ar/سياسة-الاستبدال-والإسترجاع/page-1744679997" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">سياسة الاستبدال والإسترجاع</a>
<a href="https://emzuri.com/ar/برنامج-امزوري-للتسويق-بالعمولة/page-18584154" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">برنامج امزوري للتسويق بالعمولة</a>
<a href="https://emzuri.com/ar/للمشاريع-الخاصة-و-طلبات-الشركات/page-1215765926" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">للمشاريع الخاصة و طلبات الشركات</a>
<a href="https://emzuri.com/ar/تواصل-مع-اخصائي-تنسيق-الهدايا/page-787385002" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">تواصل مع اخصائي تنسيق الهدايا</a>
<a href="https://emzuri.com/ar/سياسة-الخصوصية/page-1770276045" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">سياسة الخصوصية</a>
<a href="https://emzuri.com/ar/loyalty" target="_self" className="block mb-5 last:mb-0 text-sm leading-none">ولاء امزوري</a>
</div>
</div>
<div className="lg:-mt-[45px] relative z-[1] lg:col-[2]">
<a href="https://emzuri.com/ar/" className="flex items-center justify-center m-0">
<img src="https://cdn.salla.sa/form-builder/8w08ggcPtWCmvaWwY6UVALycxARpeCS1Wxk3dEAi.png"  alt="إمـــــــزوري" className="img-fluid emzuri-img"/>
</a>
<p className="leading-6 mb-8 mt-3 text-center ">
مرحبـــا بك في عالم إمــــــزوري،
هنــا نسعى لخلق الفرح كل يوم للناس وجعل العالم أكثر جمالاً
</p>
<div className="flex rtl:space-x-reverse space-x-2 items-end justify-center  ">
<a className="load-img-onclick" data-modal-id="modal-value-tax" href="#/" alt="310137627300003">
<img width="100%" height="100%" src="https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png" data-src="https://cdn.salla.network/images/tax.png?v=2.0.3" className="lazy w-10 rounded-sm hover:opacity-80 transition-opacity" alt="value added tax"/>
</a>
<div>
<p className="text-sm text-text-grey mb-1">الرقم الضريبي</p>
<b className="text-sm">310137627300003</b>
</div>
</div>
<salla-modal sub-title-first sub-title="الرقم الضريبي" modal-title="310137627300003" id="modal-value-tax">
<div className="flex justify-center max-w-full">
<img className="w-full" loading="lazy" src="https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png" data-src="https://cdn.salla.sa/eVgXP/2Ez3AVnFbzIbZMz9gHPVDXpkFumsUSysRUEho8bV.jpg" alt="310137627300003" />
</div>
</salla-modal>
</div>
<div>
<div className="mt-2 md:mt-5">
<a className="flex items-center justify-center flex-col h-full" href="https://eauthenticate.saudibusiness.gov.sa/certificate-details/0000047723">
<LazyLoadImage src={'https://cdn.salla.network/images/sbc.png?v=2.0.3'}
    width={70} height={70}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="mSaudi Business Center"
/>
موثق لدى منصة الأعمال
</a>
</div>
</div>
</div>
</div>
<div className="store-footer__contacts-row">
<div className="flex items-center justify-center flex-wrap gap-5   rounded_contacts">
<a href="https://emzuri.com/ar/whatsapp/send" className="flex items-center text-sm leading-none" aria-label="whatsapp">
<i className="rtl:ml-2.5 ltr:mr-2.5 border w-8 h-8 justify-center flex items-center shrink-0 contact-icon sicon-whatsapp2 gray-bg gray-border-1px"></i>
<span className="text-unicode unicode">+966538004004&#160;</span>
</a>
<a href="mailto:support@emzuri.com" className="flex items-center text-sm leading-none" aria-label="email">
<i className="rtl:ml-2.5 ltr:mr-2.5 border w-8 h-8 justify-center flex items-center shrink-0 contact-icon sicon-mail gray-bg gray-border-1px"></i>
<span className="text-unicode unicode"><span className="__cf_email__" data-cfemail="ef9c9a9f9f809d9baf8a82959a9d86c18c8082">support@emzuri.com&#160;</span></span>
</a>
</div>
<ul className="flex items-center justify-center flex-wrap gap-5 mt-4  rounded_contacts">
<li>
<a href="https://www.instagram.com/emzuriofficial"       title="انستقرام" aria-label="انستقرام" className="social-link gray-border-2px">
<i className="sicon-instagram gray-bg"></i>
</a>
</li>
<li>
<a href="https://twitter.com/EmzuriOfficial"       title="تويتر" aria-label="تويتر" className="social-link gray-border-2px">
    <svg version="1.1" className="w-[90%]" id="svg5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1668.56 1221.19" style={{ enableBackground: "new 0 0 1668.56 1221.19", xmlSpace: "preserve" }}>
<g id="layer1" transform="translate(52.390088,-25.058597)">
<path id="path1009" className="gray-fill" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
																					   h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z" />
</g>
</svg>
</a>
</li>
<li>
<a href="https://www.snapchat.com/add/emzuri.com"       title="سناب شات" aria-label="سناب شات" className="social-link gray-border-2px">
<i className="sicon-snapchat gray-bg" ></i>
</a>
</li>
<li>
<a href="https://www.tiktok.com/@emzuriofficial"       title="تيك توك" aria-label="تيك توك" className="social-link gray-border-2px">
<i className="sicon-tiktok gray-bg"></i>
</a>
</li>
<li>
<a href="https://www.youtube.com/@Emzuriofficial"       title="يوتيوب" aria-label="يوتيوب" className="social-link gray-border-2px">
<i className="sicon-youtube gray-bg"></i>
</a>
</li>
</ul>
</div>
<div className="footer-bottom">
<div className="container md:flex items-center justify-between py-4 text-center">
<span className="text-sm opacity-75 footer-rights"><p className=" mb-2.5 md:mb-0 gray-bg">الحقوق محفوظة | 2024
<a href="https://emzuri.com/ar" className="hover:text-primary"      >إمـــــــزوري</a>
</p>
</span>
<ul className="flex justify-center items-stretch rtl:space-x-reverse space-x-2">
<li className="w-12 h-8 bg-white rounded flex items-center p-1 my-ml">
<LazyLoadImage className='object-contain max-h-full loaded' src={'https://cdn.salla.network/images/payment/mada_mini.png?v=2.0.3'}
    width={600} height={400}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="mada"
/>
</li>
<li className="w-12 h-8 bg-white rounded flex items-center p-1">
<LazyLoadImage className='object-contain max-h-full loaded' src={'https://cdn.salla.network/images/payment/credit_card_mini.png?v=2.0.3'}
    width={600} height={400}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="credit_card"/>
</li>
<li className="w-12 h-8 bg-white rounded flex items-center p-1">
<LazyLoadImage className='object-contain max-h-full loaded' src={'https://cdn.salla.network/images/payment/bank_mini.png?v=2.0.3'}
    width={600} height={400}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="bank"/>
</li>
<li className="w-12 h-8 bg-white rounded flex items-center p-1">
<LazyLoadImage className='object-contain max-h-full loaded' src={'https://cdn.salla.network/images/payment/apple_pay_mini.png?v=2.0.3'}
    width={600} height={400}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="apple_pay"/>
</li>
<li className="w-12 h-8 bg-white rounded flex items-center p-1">
<LazyLoadImage className='object-contain max-h-full loaded' src={'https://cdn.salla.network/images/payment/tamara_installment_mini.png?v=2.0.3'}
    width={600} height={400}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="tamara_installment"/>
</li>
<li className="w-12 h-8 bg-white rounded flex items-center p-1">
<LazyLoadImage className='object-contain max-h-full loaded' src={'https://cdn.salla.network/images/payment/cod_mini.png?v=2.0.3'}
    width={600} height={400}
    PlaceholderSrc={'https://cdn.assets.salla.network/themes/581928698/1.77.0/images/s-empty.png'}
    alt="cod"/>
</li>
</ul>
</div>
</div>
</div>

</footer>



     );
}
 
export default Footer;