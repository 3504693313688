import React, { useState } from 'react';
import { useWarrantiesContext } from "../hooks/useWarrantiesContext";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import 'react-phone-number-input/style.css';
import { Input } from './Input'
import { FormProvider, useForm } from 'react-hook-form'
import {
  modelNo_validation,
  orderNo_validation,
  first_name_validation,
  last_name_validation,
  email_validation,
  mobileNo_validation,
  birthday_validation,
  purchase_date_validation
} from '../utils/inputValidations'
import { BsFillCheckSquareFill } from 'react-icons/bs'

const WarrantyForm = () => {
    const { dispatch } = useWarrantiesContext()
    const methods = useForm()
    const [success, setSuccess] = useState(false)
    const [modelNo, setModelNo] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [birthday, setBirthday] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [isGift, setIsGift] = useState(false);
    const [error, setError] = useState(null)
   
    const onSubmit = methods.handleSubmit(async(e) =>  {
      const warranty = {
        modelNo,
        orderNo,
        firstName,
        lastName,
        email,
        mobileNo,
        birthday,
        purchaseDate,
        isGift
      };

      const response = await fetch('/api/warranties', {
        method: 'POST',
        body: JSON.stringify(warranty),
        headers:{
            'Content-Type': 'application/json'
        }
      });

      const json = await response.json();

      if (!response.ok) {
          setError(json.error);
      }

      if (response.ok) {
        setModelNo('');
        setOrderNo('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setMobileNo('');
        setBirthday('');
        setPurchaseDate('');
        setIsGift(false);

        methods.reset(); 

        setSuccess(true);
        setError(null);
        dispatch({type: 'CREATE_Warranty', payload: json});
      }
  });
   

    return (
        <div className="create">
        <h2>منتج صنع بحب ليبقى معك للأبد</h2>
        <span className="enhanced-title-border"></span>
        <p>سجل منتجك في دقيقة واحدة</p>
        <p className="mb-4">واحصل على ضمان لعشر سنوات</p>
        <FormProvider {...methods}>
        <form
          onSubmit={e => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="container"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
            <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...first_name_validation} 
              onChange={(e) => setFirstName(e.target.value)}
              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...last_name_validation} 
              onChange={(e) => setLastName(e.target.value)}
              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...email_validation} 
              onChange={(e) => setEmail(e.target.value)}
              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...mobileNo_validation}
              value={mobileNo}
              onChange={setMobileNo}

              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...birthday_validation}
              onChange={(e) => setBirthday(e.target.value)}
              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...purchase_date_validation} 
              onChange={(e) => setPurchaseDate(e.target.value)}
              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...orderNo_validation} 
              onChange={(e) => setOrderNo(e.target.value)}
              />
              </Grid>
              <Grid item className="grid-item" xs={12} md={6} lg={6} xl={6}>
              <Input {...modelNo_validation} 
              onChange={(e) => setModelNo(e.target.value)}
              />
              </Grid>
            
              <Grid item className="grid-item" xs={12}>
                <label>هل كان المنتج هدية؟</label>
                <select
                    value={isGift}
                    onChange={(e) => setIsGift(e.target.value)}
                    >
                    <option value="true">نعم</option>
                    <option value="false">لا</option>
                </select>
                
              </Grid>
              <Grid item className="grid-item" xs={12}>
              {success && (
              <p className="flex items-center gap-1 mb-5 font-semibold text-green-500 ar">
                <BsFillCheckSquareFill /> تم إرسال طلب تسجيل المنتج بنجاح
                
              </p>
            )}
            <button
              onClick={onSubmit}
            >
          {error && <div className="error">{error}</div>}

              تسجيل المنتج
            </button>
                {/* {mobileNo+' '+firstName} */}
              </Grid>
            </Grid>
          </Box>
          </form>
      </FormProvider>
        
    
      </div>    
       
        );
}
 
export default WarrantyForm;
