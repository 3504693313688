import React from 'react';


const ImageRow = ({ images }) => {
    return (
        <div className="image-row">
            {images.map((imageUrl, index) => (
                <div className="image-item" key={index}>
                    <div className="image-overlay"></div>
                    <img src={imageUrl} alt="different products images" />
                </div>
            ))}
        </div>
    );
}

export default ImageRow;