import { createContext, useReducer } from 'react'

// Create a context for managing warranties state
export const WarrantiesContext = createContext()

// Reducer function to manage state updates
export const warrantyReducer = (state, action) => {
    switch(action.type){
        case 'SET_WARRANTIES':
            return {
                warranties: action.payload
            }
        case 'CREATE_WARRANTY':
            return{
                warranties: [action.payload, ...state.warranties]
            }
        case 'DELETE_WARRANTY':
            return {
                warranties: state.warranties.filter(w=>w._id !== action.payload._id)
                
            }
        default:
            return state
    }
}

/**
 * Provider component for WarrantiesContext.
 * @param {Object} props - Component props.
 * @param {Object} props.children - Child components to be wrapped by the provider.
 * @returns {JSX.Element} WarrantiesContext provider component.
 */
export const WarrantiesContextProvider = ({ children }) => {
    // Initialize the state and dispatch function using the useReducer hook
    const [state, dispatch] = useReducer(warrantyReducer, {
        warranties:null
    })



    // Return the context provider with the current state and dispatch function
    return (
        <WarrantiesContext.Provider value={{...state, dispatch}}>
            { children }
        </WarrantiesContext.Provider>

    )
}