/**
 * Finds input errors for a specific input field by filtering the errors object.
 * @param {object} errors - The errors object returned by react-hook-form.
 * @param {string} name - The name of the input field to find errors for.
 * @returns {object} An object containing the error message for the input field, if any.
 */
export function findInputError(errors, name) {
  // Filter the errors object to find errors for the specified input field
  const filtered = Object.keys(errors)
    .filter(key => key.includes(name))
    .reduce((cur, key) => {
      // Reduce the filtered keys to construct an object containing the error message
      return Object.assign(cur, { error: errors[key] })
    }, {})
  return filtered
}
