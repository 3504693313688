import { isValidPhoneNumber } from 'react-phone-number-input';

/**
 * Validation configuration for the model number input field.
 */
export const modelNo_validation = {
  name: 'modelNo',
  label: 'رقم الموديل',
  type: 'text',
  id: 'modelNo',
  placeholder:'ادخل رقم الموديل الموجود في الفاتورة',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },

  },
}

/**
 * Validation configuration for the order number input field.
 */
export const orderNo_validation = {
  name: 'orderNo',
  label: 'رقم الطلب',
  type: 'number',
  id: 'orderNo',
  placeholder: 'ادخل رقم الطلب...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },
  },
}

/**
 * Validation configuration for the first name input field.
 */
export const first_name_validation = {
  name: 'first-name',
  label: 'الاسم الأول',
  type: 'text',
  id: 'first-name',
  placeholder:'ادخل اسمك الأول...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },
  minLength: {
      value: 2,
      message: 'يجب ألا يقل   الاسم عن حرفين',
    },
  },
}

/**
 * Validation configuration for the last name input field.
 */
export const last_name_validation = {
  name: 'last-name',
  label: 'الاسم الأخير',
  type: 'text',
  id: 'last-name',
  placeholder: 'ادخل اسمك الأخير...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },
    minLength: {
      value: 2,
      message: 'يجب ألا يقل   الاسم عن حرفين',
    },
  },
}

/**
 * Validation configuration for the email input field.
 */
export const email_validation = {
  name: 'email',
  label: 'البريد الإلكتروني',
  type: 'email',
  id: 'email',
  placeholder: 'ادخل بريدك الإلكتروني...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'البريد الإلكتروني غير صحيح',
    },
  },
}

/**
 * Validation configuration for the mobile number input field.
 */
export const mobileNo_validation = {
  name: 'mobileNo',
  label: 'رقم الجوال',
  type: 'tel',
  id: 'mobileNo',
  isPhoneInput: true,
  placeholder: 'ادخل رقم الجوال...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },

      validPhoneNumber: (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid || 'رقم الجوال غير صحيح';
      },
  
  },
};

/**
 * Validation configuration for the birthday input field.
 */
export const birthday_validation = {
  name: 'birthday',
  label: 'تاريخ الميلاد',
  type: 'date',
  id: 'birthday',
  placeholder: 'ادخل تاريخ ميلادك ...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },

    validate: {
      notFutureDate: (value) => {
        const selectedDate = new Date(value);
        const currentDate = new Date();
        return selectedDate < currentDate || 'تاريخ الميلاد غير صحيح';
      }
    }
  },
}

/**
 * Validation configuration for the purchase date input field.
 */
export const purchase_date_validation = {
  name: 'purchase-date',
  label: 'تاريخ الشراء',
  type: 'date',
  id: 'purchase-date',
  placeholder: 'ادخل تاريخ الشراء ...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },
    validate: {
      notFutureDate: (value) => {
        const selectedDate = new Date(value);
        const currentDate = new Date();
        return selectedDate < currentDate || 'تاريخ الشراء غير صحيح';
      }
    }
    
  },
}

/**
 * Validation configuration for the isGift input field.
 */
export const isGift_validation = {
  name: 'isGift',
  label: 'isGift',
  type: 'text',
  id: 'isGift',
  placeholder: 'هل كان المنتج هدية ...',
  validation: {
    required: {
      value: true,
      message: 'مطلوب *',
    },
    
  },
}
