import React from 'react';
import ImageRow from './ImageRow'; // Import the ImageRow component

const CustomImage = () => {
    // Array of image URLs
    const images = [
        'https://cdn.salla.sa/eVgXP/a7AI2NW7lb8ECHRl9Pfxk7VfA95YbiLL95cIQ7o0.jpg',
        'https://cdn.salla.sa/eVgXP/7peJjbGdh4xPICgQEQC7MpUyCP2fH2WDMz6hUNZw.jpg',
        'https://cdn.salla.sa/eVgXP/Fa8C0cvXdT7o9KupdvY6OEVU75LbX9mIKQ0WHzFq.jpg',
        'https://cdn.salla.sa/eVgXP/NZGEF1ZcDr8WbcdxhTlNqXF5m4VoA0CyJnzHWxp0.jpg',
        'https://cdn.salla.sa/eVgXP/57YCUz1inhDunrNL1ETpN7p1k4ttEuoQc2NEDG8R.jpg',
        'https://cdn.salla.sa/eVgXP/KY8tzR3VACsMBSLnWUfR55oiFFjblBbyfRIvPxrB.jpg',
        'https://cdn.salla.sa/eVgXP/7vEZOO9Vc3ORBwwXQAojIVdRwOFElyMkvOrWSsiS.jpg',
        'https://cdn.salla.sa/eVgXP/EeL2fuabRmoAlyYd4NYriNUeDY9tqUFzhBOby9Zi.jpg',
        'https://cdn.salla.sa/eVgXP/szop5jZaiNq3TEbs6p8VNOpooobApYvsFQovSgLA.jpg',
        'https://cdn.salla.sa/eVgXP/W9uUicjxtmPPNxJoJkOet3GYhHaXdcLC0lFdjwLS.png',
       
    ];
    return (
        
                <ImageRow images={images}/> //Render the ImageRow component and pass the images array as a prop
          
    );
}

export default CustomImage;
