import { WarrantiesContext } from "../context/WarrantyContext";
import { useContext } from "react";

/**
 * Custom hook to access the warranties context and its associated state and dispatch function.
 * @returns {object} The warranties context object.
 * @throws {Error} Throws an error if used outside a WarrantyContextProvider.
 */
export const useWarrantiesContext = () => {
    const context = useContext(WarrantiesContext)

    // Throw an error if the hook is used outside a WarrantyContextProvider
    if(!context){
        throw Error('useWarrantiesContext must be used inside a WarrantyContextProvider')
    }

    return context
}