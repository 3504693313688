import { BrowserRouter, Routes, Route } from 'react-router-dom'

//pages & components
import Home from './pages/Home';
import Navbar from './components/Navbar';
import CustomImage from './components/CustomImage';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Navbar/>
     {/* <ImageComponent/> */}
     <CustomImage/>
     <div className="pages">
     <Routes>
      <Route 
      path='/'
      element={<Home/>}
      />
      <Route 
      path='/*'
      element={<Home/>}
      />
      
      </Routes>
     </div>
     <Footer/>
     </BrowserRouter>
    </div>
  );
}

export default App;
